import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="window-closer"
export default class extends Controller {
  static values = {
    waitTime: { type: Number, default: 400 },
  }

  connect() {
    window.setTimeout(() => this.closeWindow(), this.waitTimeValue)
  }

  closeWindow() {
    window.close()
  }
}
